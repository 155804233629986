<template>
  <div class="settings">
    <div class="container">
      <div class="settings__title">
        <h3>{{$t('settings.app_wide')}}</h3>
      </div>
      <form @submit.prevent="submit1" class="box mg-t-30 pd-25 with-blue-top">
        <div class="flex align-center justify-between settings__head mg-b-15">
          <h4>{{$t('settings.user_discounts')}}</h4>
          <button class="btn btn--red">{{$t('global.save')}}</button>
        </div>
        <div class="flex align-center justify-start pd-t-5 pd-b-5 pd-l-15 pd-r-15">
          <div class="table-title blue flex-50">{{$t('settings.user_types')}}</div>
          <div class="table-title blue flex-25">{{$t('settings.discount_rate')}}</div>
        </div>
        <div class="flex align-center justify-start with-shadow pd-t-5 pd-b-5 pd-l-15 pd-r-15">
          <div class="table-text gray flex-50">{{$t('settings.first_time')}}</div>
          <div class="form-element flex-25">
            <input type="number" required v-model="first_time_user_discount.value" class="form-control"/>
          </div>
        </div>
        <div class="flex align-center justify-start with-shadow pd-t-5 pd-b-5 pd-l-15 pd-r-15">
          <div class="table-text gray flex-50">{{$t('settings.all_users')}}</div>
          <div class="form-element flex-25">
            <input type="number" required v-model="all_user_discount.value" class="form-control"/>
          </div>
        </div>
      </form>
      <form @submit.prevent="submit2" class="box mg-t-30 pd-25 with-red-top">
        <div class="flex align-center justify-between settings__head mg-b-15">
          <h4>{{$t('settings.point_conv')}}</h4>
          <button class="btn btn--red">{{$t('global.save')}}</button>
        </div>
        <div class="flex align-center justify-start pd-t-5 pd-b-5 pd-l-15 pd-r-15">
          <div class="table-title red flex-33">{{$t('settings.cash_amount')}}</div>
          <div class="table-title red flex-33">{{$t('settings.point_amount')}}</div>
          <div class="table-title red flex-33">{{$t('settings.conv_rate')}}</div>
        </div>
        <div class="flex align-center justify-start with-shadow pd-t-5 pd-b-5 pd-l-15 pd-r-15">
          <div class="table-text gray flex-33">100</div>
          <div class="form-element flex-33 flex">
            <input type="number" step="0.1" min="0" required v-model="lifes_point_conversion.value" class="form-control flex-80"/>
          </div>
          <div class="table-text gray flex-33">0.5%</div>
        </div>
      </form>
    </div>
    <loading v-if="isLoading"/>
  </div>
</template>

<script>
import Loading from '@/components/loading/Loading'
import { Settings } from '@/api/api'
export default {
  name: 'GeneralSettings',
  components: {
    Loading
  },
  data () {
    return {
      settings: {},
      form: {},
      first_time_user_discount: {
        id: 0,
        value: 0
      },
      all_user_discount: {
        id: 0,
        value: 0
      },
      lifes_point_conversion: {
        id: 0,
        value: 0
      },
      isLoading: true
    }
  },
  mounted () {
    this.loadSettings()
  },
  methods: {
    async loadSettings () {
      try {
        const { data } = await Settings.get()

        data.forEach((set) => {
          this[set.type] = set
        })
      } catch (e) {
        this.$toast.error(this.$t('settings.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async submit1 () {
      this.isLoading = true

      try {
        await Promise.all([
          Settings.update(this.first_time_user_discount.id, {
            type: 'first_time_user_discount',
            value: this.first_time_user_discount.value
          }),
          Settings.update(this.all_user_discount.id, {
            type: 'all_user_discount',
            value: this.all_user_discount.value
          })
        ])
      } catch (err) {
        this.$toast.error(this.$t('settings.update_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async submit2 () {
      this.isLoading = true

      try {
        await Promise.all([
          Settings.update(this.lifes_point_conversion.id, {
            type: 'lifes_point_conversion',
            value: this.lifes_point_conversion.value
          })
        ])
      } catch (err) {
        this.$toast.error(this.$t('settings.update_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style.scss";
</style>
